// custom dependencies
import AOS from 'aos';
// import Scroller from '../scroller.js';

const deps = {
  AOS: AOS,
  // Scroller : Scroller
};

export default deps;
