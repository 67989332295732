import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
//window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
import libs from './lib/dep';
window.libs = libs;
// npm install object-fit-images
import objectFitImages from 'object-fit-images';
// npm install swiper
import { Swiper, Navigation, Autoplay, EffectFade } from 'swiper';

Swiper.use([Navigation, Autoplay, EffectFade]);

$(document).foundation();

// function to make mailto from span
  $.fn.buildMailTo = function() {
    var $t = this; // freakin scope
    var pre     = $t.data('pre'),
        post    = $t.data('post'),
        subject = $t.data('subject'),
        classes = $t.attr('class'),
        text    = $t.text();

    var href = 'mailto:' + pre + '@' + post;
    if ( subject )
      href = href + '?subject=' + subject;

    if ( !text )
      text = pre + '@' + post;


    $t.replaceWith( '<a href="' + href + '" class="' + classes + '">' + text + '</a>' );
    return this;
  }


var apolloGdprYoutube = {

  init: function () {
    console.log('Module Executed: GDPR Youtube');

    apolloGdprYoutube.bindYTActions();
  },

  bindYTActions: function() {
    $('.yt-disc-play').on('click',function() {
      var $next = $(this).next();
      $next.css({'padding-bottom':'90px'});
      setTimeout(function() { $next.css({'padding-bottom':''}); }, 2000);
    });

    $('button.load-video').on('click',function() {
      apolloGdprYoutube.loadVideo( $(this).parents('.flex-video') );
    });
  },

  loadVideo: function( $container ) {
    var vid = $container.attr('data-video');
    $container.empty().html('<iframe src="'+ vid +'&autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
  },
}

var apolloAnchorlinks = {
  init: function () {
    console.log('Module Executed: Anchor Links');

    apolloAnchorlinks.bindAction();
    apolloAnchorlinks.checkUrl();
  },

  bindAction: function () {
    $('a[href^="#"]').click(function(e) {
      e.preventDefault();
      let id = $(this).attr('href');
      if( id.length ) {
        apolloAnchorlinks.goToTarget( id );
        if( window.history != undefined && window.history.pushState != undefined) {
          window.history.pushState({}, document.title, window.location.pathname + id);
        }
      }
    });
  },

  checkUrl: function() {
    let hash = window.location.hash;
    if ( hash.length ) {
      apolloAnchorlinks.goToTarget( hash );
    }
  },

  goToTarget: function( id ) {
    Foundation.SmoothScroll.scrollToLoc(id, {
      threshold: 50,
      offset: 150
    }, function() {
       //console.log('scrolled');
    });
  }
}



$(document).ready(function() {
  // IE polyfill for image object fit
  objectFitImages();

  apolloGdprYoutube.init();
  apolloAnchorlinks.init();



  $('.liambtn').each(function() {
    $(this).buildMailTo();
  });

  $('.to-top').on('click', function(){
    $('html, body').animate({ scrollTop: 0 }, 1000 );
  });

});
